// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/jumbo.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n  scroll-behavior: smooth;\n}\n\nbody {\n  font-size: 1.5rem;\n  font-weight: 400;\n  line-height: 1.5;\n}\n\n/* Nabar */\n\n.navbar {\n  padding-left: 5%;\n  padding-right: 5%;\n}\n\n.navbar-nav {\n  font-size: 1.4rem;\n  font-weight: 260;\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n}\n\n/* Jumbotron */\n\n.jumbotron {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n\n.marked {\n  background-color: rgba(74, 74, 74, 0.5);\n}\n\n/* Scroll spy */\nbody {\n  position: relative;\n}\n\n/* Logo */\n\n.navbar-brand img {\n  max-height: 40px;\n}\n", "",{"version":3,"sources":["webpack://src/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,UAAU;;AAEV;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,2DAA2D;AAC7D;;AAEA,cAAc;;AAEd;EACE,yDAA4C;EAC5C,sBAAsB;AACxB;;AAEA;EACE,uCAAuC;AACzC;;AAEA,eAAe;AACf;EACE,kBAAkB;AACpB;;AAEA,SAAS;;AAET;EACE,gBAAgB;AAClB","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\n\nbody {\n  font-size: 1.5rem;\n  font-weight: 400;\n  line-height: 1.5;\n}\n\n/* Nabar */\n\n.navbar {\n  padding-left: 5%;\n  padding-right: 5%;\n}\n\n.navbar-nav {\n  font-size: 1.4rem;\n  font-weight: 260;\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n}\n\n/* Jumbotron */\n\n.jumbotron {\n  background-image: url(\"../images/jumbo.jpg\");\n  background-size: cover;\n}\n\n.marked {\n  background-color: rgba(74, 74, 74, 0.5);\n}\n\n/* Scroll spy */\nbody {\n  position: relative;\n}\n\n/* Logo */\n\n.navbar-brand img {\n  max-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
